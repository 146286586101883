@media (max-width: 700px) {
  .landingPageCardContainer {
    flex-direction: column;
  }

  #landingPageHeading {
    font-size: 3.15rem !important;
  }

  .landingPageMainIntro {
    width: 100% !important;
  }

  .logo {
    height: 36px;
  }

  .landingWelcomeNoteAndImg {
    padding: 24px;
    padding-top: 16px;
  }

  #headerFreeButton {
    display: flex;
    margin-bottom: 6px;
    margin-top: 2px;
    justify-content: center;
    margin-left: 40px;
  }

  #homePageHeader #headerFreeButton {
    margin-left: 0px;
  }

  .landingPageCard {
    max-width: 100%;
  }

  .landingPageMainIntroHeader {
    font-size: 22px;
  }

  .landingPageMainIntro {
    flex: 1;
  }

  .landingPageImgScroll {
    display: none;
  }

  .landingPageMainIntroContent {
    margin-top: 16px;
    font-size: 16px;
  }

  .landingPageContentContainer {
    padding: 0 16px;
  }

  .landingPageWhatsNewSection {
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 0;
    margin-right: 0;
  }

  .landingPageFooterContent,
  .FooterLinks {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 16px;
  }

  .landingPageFooterContent {
    padding-bottom: 0;
  }

  .landingPageFooterContent p {
    min-width: 100%;
    padding-bottom: 4px;
  }

  .footerLogo {
    width: 58px;
  }

  .landingPageFooterLinks {
    margin-bottom: 8px;
  }

  .contentComponentMainContainer {
    flex: 1;
    min-width: 100%;
  }

  .pageTitle {
    margin-top: 14px !important;
  }

  #insideOut {
    display: block;
  }

  .drawerList {
    position: relative;
  }

  .landingPageFooterContent .landingPageFooter {
    padding-top: 8px;
  }

  #footerDivider {
    margin-bottom: 18 px !important;
  }

  .cveDetailPadding {
    padding-top: 24px !important;
  }

  .cveDetailPadding .allCveButton {
    top: 30px !important;
  }

  .cveDetailPadding .allDefinitionButton {
    top: 26px !important;
  }

  #logoAndIconContainer {
    min-height: 64px;
    max-height: 64px;
  }

  .landingPageHeaderContainer .MuiToolbar-root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow: hidden;
    height: 64px;
    transition: all 0.25s ease-out;
    padding-bottom: 10px;
  }

  .landingPageHeaderContainer .open .MuiToolbar-root {
    height: 122px;
  }

  .landingPageHeaderContainer #headerOpener {
    transition: all 0.25s ease-out;
  }

  .landingPageHeaderContainer .open #headerOpener {
    transform: rotate(180deg);
  }

  .landingPageHeaderContainer .profile-icon-container {
    position: absolute;
    right: 16px;
  }
}

@media (max-width: 1024px) {
  .socialIconsFooter {
    padding-right: 36px;
  }

  .loginShield {
    width: 150px;
    height: 150px;
    top: 458px;
    left: 351px;
  }

  .landingPageCard {
    max-width: 100%;
  }

  .landingPageCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 48px;
  }

  .designWrapper .loginRoot {
  }

  #blogButton {
    padding-left: 0;
  }

  .product-detail {
    margin-bottom: 32px;
  }

  .product-desc {
    font-size: 1.25rem;
  }

  .ct-rocket-container {
    display: none;
  }
}

@media (max-width: 768px) {
  .landingPageCard {
    padding: 0;
  }

  .cveDetailWrapper,
  .definitionDetailWrapper {
    max-width: 90%;
  }

  .allCveButton,
  .allDefinitionButton {
    left: -20px !important;
    top: 6px !important;
  }

  .allDefinitionButton {
    top: 0 !important;
  }

  .definitionDetailHeader {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .definitionDetailHeader .detailHeaderPill {
    margin: 0;
    margin-top: 4px;
  }

  .versionSeverity,
  .platformProductWrapper {
    flex-direction: column !important;
  }

  .versionSeverity > div {
    padding: 0 !important;
  }

  .versionSeverity .MuiTableCell-body,
  .definitionDetailWrapper .MuiTableCell-body {
    word-break: break-all;
  }

  .cveDetailWrapper .MuiCollapse-wrapperInner .MuiListItem-root {
    padding-left: 20px !important;
  }

  main {
    overflow: hidden;
  }

  #landingPageHeaderSupport .MuiToolbar-root {
    flex-direction: row;
  }

  .ct-cloud-left,
  .ct-cloud-right-container,
  .ct-cloud-bottom-container,
  .ct-rocket-container {
    display: none;
  }
}

@media (min-width: 720px) {
  .landingPageCard {
    max-width: 100%;
  }

  #headerOpener {
    display: none;
  }

  .definitionDetailHeader {
    flex-direction: row;
  }

  .definitionDetailHeader .detailHeaderPill {
    margin: 0;
    margin-left: 4px;
  }

  .definitionDetailHeader .detailHeaderPill:nth-child(1) {
    margin-left: 8px;
  }

  .allCveButton,
  .allDefinitionButton {
    left: 0px !important;
  }
}

@media (min-width: 720px) and (max-width: 1024px) {
  .landingPageCard {
    width: 96%;
    margin: 6px auto;
    padding: 0;
  }

  .landingPageCard .cardMedia {
    min-width: 400px;
    max-width: 400px;
  }

  .landingPageCard .MuiCardContent-root {
    display: flex;
    flex-direction: row;
  }

  .landingPageCard .cardContent {
    margin-left: 24px;
  }

  .landingPageCard .landingPageCardContentHeader {
    margin-top: 0 !important;
  }
}

@media (min-width: 1240px) {
  .allCveButton,
  .allDefinitionButton {
    left: 10 !important;
  }

  .landingPageHeaderContainer .MuiToolbar-root {
    justify-content: center;
  }
}

@media (max-height: 740px) {
  .landingPageMainIntro {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
}

@media (min-width: 1074px) and (max-width: 1120px) {
  .landingPageHeaderButton {
    margin-right: 6px !important;
    padding: 6px 8px !important;
  }
}

@media (min-width: 1024px) {
  #insideOut {
    position: relative;
  }

  .inside-out-underline-wrapper {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -30px;
  }
}

@media (min-width: 1074px) {
  .mobileDrawerList {
    display: none;
  }

  .desktopNavigation {
    flex: 1;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }

  #closeDrawerButton {
    display: none;
  }
}

@media (min-width: 1560px) {
  .designWrapper > .logoContainer {
    margin-bottom: 24px;
  }

  .designWrapper > .logoContainer .logo {
    height: 5rem;
  }

  .terminalHelp {
    max-width: 1200px;
    font-size: 1.25em;
  }

  .terminalContainer {
    min-height: 600px;
    width: 97vh;
    max-width: 1200px;
  }

  .terminal p {
    font-size: 2em;
    max-height: 6.75rem;
    margin: 1rem;
  }

  .landingWelcomeNoteAndImg {
    min-height: 40vh;
  }
}

@media only screen and (min-width: 1440px) {
  #selectorOptions {
    min-width: 1200px;
    margin: auto;
  }
}

@media only screen and (max-width: 540px) {
  .privacy-policy-wrapper p.MuiTypography-body1 {
    font-size: 0.75rem;
  }

  .ct-cloud-left,
  .ct-cloud-right-container,
  .ct-cloud-bottom-container,
  .ct-rocket-container {
    display: none;
  }

  .fullLogoContainer {
    left: calc(50% - 88px);
  }

  .product-detail {
    margin-top: 32px;
    text-align: center;
  }

  .product-desc {
    text-align: center;
  }
}

@media only screen and (max-width: 360px) {
  .product-desc {
    font-size: 1rem;
  }

  .activate .MuiButton-label,
  .login .MuiButton-label {
    font-size: 12px;
  }
}

@media only screen and (max-width: 280px) {
  .activate .MuiButton-label,
  .login .MuiButton-label {
    font-size: 12px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 959px) {
  .product-detail {
    margin-bottom: 32px;
    text-align: center;
  }

  .product-desc {
    font-size: 1.25rem;
    text-align: center;
  }

  .ct-rocket-container {
    display: none;
  }

  .fullLogoContainer {
    left: calc(50% - 88px);
  }

  .xcloud-aws {
    width: 80px;
  }
}

@media only screen and (max-width: 959px) and (max-height: 740px) {
  .designWrapper {
    min-height: 752px;
    height: auto;
  }

  .designWrapper.aws {
    min-height: 830px;
  }

  .xcloud-aws {
    margin-top: 36px;
  }
}

@media only screen and (min-width: 960px) and (max-height: 450px) {
  .designWrapper {
    min-height: 550px;
  }
}

@media only screen and (min-width: 960px) {
  .login-form-wrapper,
  .signup-form-wrapper {
    min-width: 512px;
  }
}

@media only screen and (min-height: 840px) {
  .ob-card.card-container {
    top: 36px;
  }
}

@media only screen and (max-height: 850px) {
  .xcloud-aws {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 1100px) {
  .compliance-layout .comp-title-desc-section {
    width: calc(100% - 100px);
    margin-bottom: 1.5rem;
  }

  .compliance-layout .comp-title-desc-section .comp-responsive-title-text {
    overflow: visible;
    white-space: unset;
  }

  .compliance-layout .compliance-passed-failed-wrapper {
    margin-left: 6rem;
  }
}

@media only screen and (max-width: 935px) {
  .compliance-layout .compliance-progress-wrapper {
    width: 100%;
  }
  .compliance-layout .complianceProgressContainer {
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .compliance-layout .comp-title-desc-section {
    width: 100%;
  }

  .compliance-layout .compliance-passed-failed-wrapper {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .compliance-layout .view-details-btn-wrapper {
    display: flex;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1335px) {
  .subscription-card {
    display: none;
  }

  .subscription-card-responsive {
    display: block !important;
  }

  .subscription-item,
  .subscription-item-selected {
    width: auto;
  }

  .align-flex-start {
    align-items: flex-start !important;
  }

  .need-upgrade-text {
    width: auto !important;
    text-align: center;
  }
}
